import { lazy } from 'react';

// project-imports
import MainLayout from 'layout/MainLayout';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import PermissionGuard from 'utils/route-guard/PermissionGuard';

const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/error/404')));
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/error/500')));
const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction/under-construction')));
const MaintenanceComingSoon = Loadable(lazy(() => import('pages/maintenance/coming-soon/coming-soon')));

// render - sample page
const SamplePage = Loadable(lazy(() => import('pages/extra-pages/sample-page')));
const SamplePage2 = Loadable(lazy(() => import('pages/extra-pages/sample-page2')));

const SamplePage3 = Loadable(lazy(() => import('pages/extra-pages/sample-page3')));
const ViewLayout = Loadable(lazy(() => import('pages/location/locationList')));

// setting
const AppLogo = Loadable(lazy(() => import('pages/settings/AppLogo')));
const General = Loadable(lazy(() => import('pages/settings/General')));
const Email = Loadable(lazy(() => import('pages/settings/Email')));
const Payment = Loadable(lazy(() => import('pages/settings/Payment')));
const GoogleMapapi = Loadable(lazy(() => import('pages/settings/GoogleMapapi')));
const LcdwPaymentSettings = Loadable(lazy(() => import('pages/settings/LcdwPaymentSettings')));
const EmailPreferences = Loadable(lazy(() => import('pages/settings/EmailPreferences')));
const FileFormat = Loadable(lazy(() => import('pages/settings/FileFormatSize')));
const MailTemplate = Loadable(lazy(() => import('pages/settings/EmailTemplate')));
const ViewMailTemplate = Loadable(lazy(()=> import('pages/settings/ViewEmailTemplate')));
const ListEmail = Loadable(lazy(() => import('pages/settings/List Email Template')));
const EditEmail = Loadable(lazy(() => import ('pages/settings/Edit Email Template')));
const Currency = Loadable(lazy(() => import('pages/settings/Currency')));
const Location = Loadable(lazy(() => import('pages/settings/LocationLimits')));
const LocationLogs = Loadable(lazy(()=> import('pages/location/locationlogs')));

// company user
const CompanyUserView=Loadable(lazy(() => import('pages/user/companyuser/view')));
const CompanyUser=Loadable(lazy(()=> import('pages/user/companyuser/user')));
const CompanySubuser = Loadable(lazy(()=> import('pages/user/companyuser/subuser')));

//lcdw user
const LcdwUserView=Loadable(lazy(() => import('pages/user/lcdwuser/view')));
const LcdwUserActivity = Loadable(lazy(() => import('pages/user/lcdwuser/viewlcdwuseractivity')));

// admin user
//roles
const ListOfRoles=Loadable(lazy(() => import('pages/user/adminuser/subuserroles/roles/listofroles')));
const AddRoles=Loadable(lazy(() => import('pages/user/adminuser/subuserroles/roles/addrole')));
const EditRoles=Loadable(lazy(() => import('pages/user/adminuser/subuserroles/roles/editrole')));
const Permission=Loadable(lazy(() => import('pages/user/adminuser/subuserroles/permission')));

    // sub Users
const ListOfSubUser=Loadable(lazy(() => import('pages/user/adminuser/subusermangement/listofadminsubuser')));
const ViewSubUserActivity=Loadable(lazy(() => import('pages/user/adminuser/subusermangement/viewsubuseractivity')));
const ViewActivity = Loadable(lazy(() => import('pages/user/adminuser/subusermangement/viewactivity')));

// Staff Users
const ListOfAdminStaffUsers=Loadable(lazy(() => import('pages/user/adminuser/staffusermangement/listofadminstaffusers')));
const ViewStaffUserActivity=Loadable(lazy(() => import('pages/user/adminuser/staffusermangement/viewstaffuseractivity')));
const ViewStaffFollowUpList=Loadable(lazy(() => import('pages/user/adminuser/staffusermangement/staff-followuplist')));


// category
const CategoryView=Loadable(lazy(() => import('pages/category/view')));
const CategoryEdit=Loadable(lazy(() => import('pages/category/edit')));

// Payment
// const Invoices=Loadable(lazy(() => import('pages/payment-statement/invoices')));

//location
const AddLocation = Loadable(lazy(() => import('pages/location/LocationAdd')));
const EditLocation = Loadable(lazy(() => import('pages/location/locationEdit')));
const ViewLocation = Loadable(lazy(() => import('pages/location/LocationView')));
const PaymentRequestlist = Loadable(lazy(() => import('pages/location/payment-request-list')));


//view request
const LcdwService = Loadable(lazy(() => import('pages/request/RequestMain')));
const UtilitiesPlan = Loadable(lazy(() => import('pages/request/UtilitiesRequest')));
const AuthoritiesApproval = Loadable(lazy(() => import('pages/request/AuthoritiesRequest')));
const Survey = Loadable(lazy(() => import('pages/request/SurveyRequest')));
const GprSurveys = Loadable(lazy(() => import('pages/request/GPRSurveys')));

const RequestView = Loadable(lazy(() => import(`pages/request/RequestView`)));
const EditLocationRequest = Loadable(lazy(() => import(`pages/request/RequestEdit`)));

const ViewLocationRequest = Loadable(lazy(() => import(`pages/request/viewData`)));
// industry
const IndustryList=Loadable(lazy(() => import('pages/Industry/industryList')));
// country
const CountryList = Loadable(lazy(()=> import('pages/country/countryList')));

//cms
const CreateCms =Loadable(lazy(() => import('pages/cms/create')));
const ListCms =Loadable(lazy(() => import('pages/cms/list')));
const EditCms =Loadable(lazy(()=> import('pages/cms/edit')));
const ViewCms = Loadable(lazy(()=> import('pages/cms/view')));
// const PreviewPage= Loadable(lazy(()=> import('pages/cms/previewpage')));
const LandingPage = Loadable(lazy(()=> import('components/LandingPage')));

//Feedback
const FeedbackContact=Loadable(lazy(() => import('pages/settings/feedback-form')));
const LocationFeedback = Loadable(lazy(()=> import('pages/settings/location-feedback')));

const Header = Loadable(lazy(() => import('pages/settings/Header')));
// const Activity = Loadable(lazy(() => import('pages/settings/Activity')));
const Footer = Loadable(lazy(() => import('pages/settings/Footer')));

const LoginLogs = Loadable(lazy(()=> import('pages/logs/logsMain')));
const CartCheckList = Loadable(lazy(()=> import('pages/checklist/cart')));

//photoapproval
const PhotoApproval = Loadable(lazy(() => import('pages/location/photoApproval/photoApprList')));
const EditLocationData = Loadable(lazy(() => import('pages/location/photoApproval/EditLocationData')));
const ViewLocationData = Loadable(lazy(() => import('pages/location/photoApproval/ViewLocationData')));

// ==============================|| MAIN ROUTES ||============================== //

// const ViewLayout = () => {
//   return (
//     <div>
//       <WidgetStatistics />
//       <PaginationTable />
//     </div>
//   );
// };

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/landingpage',
      element: <LandingPage />
    },
    {
      path: '/preview/:id',
      element: <ViewCms />
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'admin/dashboard',
          element: <SamplePage />
        },
        {
          path: 'admin/category/view',
          // element: <CategoryView/>, // Use ViewLayout for viewing data
          element: (
            <PermissionGuard moduleId="Category">
              <CategoryView />
            </PermissionGuard>
          ),
        },
        // {
        //   path: 'admin/category/add',
        //   element: <CategoryAdd/>
        // },
        {
          path: 'admin/category/edit/:id',
          // element: <CategoryEdit/> 
          element: (
            <PermissionGuard moduleId="Category">
              <CategoryEdit />
            </PermissionGuard>
          ),
        },
        {
          path: 'admin/location/list',
          // element: <ViewLayout />
          element: (
            <PermissionGuard moduleId="Location">
              <ViewLayout />
            </PermissionGuard>
          ), 
        },
                {
          path: 'admin/location/add',
          // element: <AddLocation  /> 
          element: (
            <PermissionGuard moduleId="Location">
              <AddLocation />
            </PermissionGuard>
          ), 
        },
        {
          path: '/admin/location/edit/:id',
          // element: <EditLocation  />
          element: (
            <PermissionGuard moduleId="Location">
              <EditLocation />
            </PermissionGuard>
          ), 
        },
        {
          path: 'admin/location/view/:id',
          // element: <ViewLocation  />
          element: (
            <PermissionGuard moduleId="Location">
              <ViewLocation />
            </PermissionGuard>
          ),
        },
        {
          path: 'admin/location/location-orders',
          element: < PaymentRequestlist />
        },
        {
          path: 'admin/location/photo-approval',
          // element: <PhotoApproval />
          element: (
            <PermissionGuard moduleId="Location">
              <PhotoApproval />
            </PermissionGuard>
          ),
        },
        {
          path: 'admin/location/edit-data/:id',
          // element: <EditLocationData />
          element: (
            <PermissionGuard moduleId="Location">
              <EditLocationData />
            </PermissionGuard>
          ),
        },
        {
          path: 'admin/location/view-data/:id',
          // element: <ViewLocationData />
          element: (
            <PermissionGuard moduleId="Location">
              <ViewLocationData />
            </PermissionGuard>
          ),
        },
        {
          path: 'admin/request/lcdwservices',
          // element: <ViewRequest />
          element: (
            <PermissionGuard moduleId="ViewRequests">
              <LcdwService />
            </PermissionGuard>
          ),
        },
        {
          path: 'admin/request/utilitiesplan',
          // element: <ViewRequest />
          element: (
            <PermissionGuard moduleId="ViewRequests">
              <UtilitiesPlan />
            </PermissionGuard>
          ),
        },
        {
          path: 'admin/request/authorities-approval',
          // element: <ViewRequest />
          element: (
            <PermissionGuard moduleId="ViewRequests">
              <AuthoritiesApproval />
            </PermissionGuard>
          ),
        },
        {
          path: 'admin/request/servey',
          // element: <ViewRequest />
          element: (
            <PermissionGuard moduleId="ViewRequests">
              <Survey />
            </PermissionGuard>
          ),
        },
        {
          path: 'admin/request/gpr-surveys',
          // element: <ViewRequest />
          element: (
            <PermissionGuard moduleId="ViewRequests">
              <GprSurveys />
            </PermissionGuard>
          ),
        },
        {
          path: '/admin/location/request-edit/:id',
          // element: <EditLocation  />
          element: (
            <PermissionGuard moduleId="ViewRequests">
              <EditLocationRequest />
            </PermissionGuard>
          ), 
        },
        {
          path: '/admin/request/view-data/:id',
          // element: <EditLocation  />
          element: (
            <PermissionGuard moduleId="ViewRequests">
              <ViewLocationRequest />
            </PermissionGuard>
          ), 
        },
        {
          path: 'admin/request/:id',
          // element: <RequestView />
          element: (
            <PermissionGuard moduleId="ViewRequests">
              <RequestView />
            </PermissionGuard>
          ),
        },
        {
          path: 'admin/location/photo-approval',
          element: <SamplePage2 /> 
        },
        {
          path: 'admin/setting/sitesetting/applogo',
          // element: <AppLogo /> 
          element: (
            <PermissionGuard moduleId="Setting">
              <AppLogo />
            </PermissionGuard>
          ),
        },
        {
          path: 'admin/setting/sitesetting/general',
          // element: <General /> 
          element: (
            <PermissionGuard moduleId="Setting">
              <General />
            </PermissionGuard>
          ),
        },
        {
          path: 'admin/setting/sitesetting/email',
          // element: <Email /> 
          element: (
            <PermissionGuard moduleId="Setting">
              <Email />
            </PermissionGuard>
          ),
        },
         {
          path: 'admin/email-template/list-email',
          // element: <ListEmail />
          element: (
            <PermissionGuard moduleId="Setting">
              <ListEmail />
            </PermissionGuard>
          ),
        },
        {
          path: 'admin/mailtemplate/view/:id',
          // element: <ViewMailTemplate />
          element: (
            <PermissionGuard moduleId="Setting">
              <ViewMailTemplate />
            </PermissionGuard>
          ),
        },
        {
          path: 'admin/template/edit/:id',
          // element: < EditEmail />
          element: (
            <PermissionGuard moduleId="Setting">
              <EditEmail />
            </PermissionGuard>
          ),
        },
        {
          path: 'admin/setting/sitesetting/payment',
          // element: <Payment  /> 
          element: (
            <PermissionGuard moduleId="Setting">
              <Payment />
            </PermissionGuard>
          ),
        },
        {
          path: 'admin/setting/sitesetting/googlemapapi',
          // element: <GoogleMapapi /> 
          element: (
            <PermissionGuard moduleId="Setting">
              <GoogleMapapi />
            </PermissionGuard>
          ),
        },
        {
          path: 'admin/setting/sitesetting/lcdwpaymentsettings',
          // element: < LcdwPaymentSettings />
          element: (
            <PermissionGuard moduleId="Setting">
              <LcdwPaymentSettings />
            </PermissionGuard>
          ),
        },
        {
          path: 'admin/setting/sitesetting/emailpreferences',
          // element: < EmailPreferences />
          element: (
            <PermissionGuard moduleId="Setting">
              <EmailPreferences />
            </PermissionGuard>
          ),
        },
        {
          path: 'admin/setting/sitesetting/fileformatsize',
          // element: < FileFormat />
          element: (
            <PermissionGuard moduleId="Setting">
              <FileFormat />
            </PermissionGuard>
          ),
        },
        // {
        //   path: 'admin/setting/sitesetting/default-location',
        //   element: < DefaultCountry/>
        // },
        {
          path: 'admin/email-template/create-email',
          // element: <MailTemplate />
          element: (
            <PermissionGuard moduleId="Setting">
              <MailTemplate />
            </PermissionGuard>
          ),
        },
        {
          path: 'admin/setting/sitesetting/email-template/header',
          // element: <Header /> 
          element: (
            <PermissionGuard moduleId="Setting">
              <Header />
            </PermissionGuard>
          ),
        },
        {
          path: 'admin/setting/sitesetting/email-template/footer',
          // element: <Footer /> 
          element: (
            <PermissionGuard moduleId="Setting">
              <Footer />
            </PermissionGuard>
          ),
        },
        // {
        //   path: 'admin/setting/sitesetting/email-template/activity',
        //   element: <Activity/> 
        // },
        {
          path: 'admin/setting/sitesetting/currency',
          // element: < Currency />
          element: (
            <PermissionGuard moduleId="Setting">
              <Currency />
            </PermissionGuard>
          ),
        },
        {
          path: 'admin/setting/sitesetting/location-limits',
          // element: < Location/>
          element: (
            <PermissionGuard moduleId="Setting">
              <Location />
            </PermissionGuard>
          ),
        },
        {
          path: 'admin/cms/add',
          // element: < CreateCms />
          element: (
            <PermissionGuard moduleId="Cms">
              <CreateCms />
            </PermissionGuard>
          ),
        },
        {
          path: 'admin/cms/list',
          // element: < ListCms />
          element: (
            <PermissionGuard moduleId="Cms">
              <ListCms />
            </PermissionGuard>
          ),
        },
         {
          path: 'admin/cms/edit/:id',
          // element: < EditCms />
          element: (
            <PermissionGuard moduleId="Cms">
              <EditCms />
            </PermissionGuard>
          ),
        },
         {
          path: 'admin/cms/view/:id',
          // element: <ViewCms />
          element: (
            <PermissionGuard moduleId="Cms">
              <ViewCms />
            </PermissionGuard>
          ),
        },
        // {
        //   path: 'admin/cms/preview',
        //   element: <PreviewPage/>
        // },
        {
          path: 'admin/sample-page3',
          element: <SamplePage3 />
        },
        {
          path: 'admin/company/view',
          // element: <CompanyUserView />
          element: (
            <PermissionGuard moduleId="company_users">
              <CompanyUserView />
            </PermissionGuard>
          ),
        },
        {
          path: 'admin/company-users/view',
          // element: <CompanyUser />
          element: (
            <PermissionGuard moduleId="company_users">
              <CompanyUser />
            </PermissionGuard>
          ),
        },
        // {
        //   path: 'admin/company-users/add',
        //   element: <CompanyUserAdd />
        // },
        {
          path: 'admin/lcdw-user/view',
          // element: <LcdwUserView />
          element: (
            <PermissionGuard moduleId="lcdw_users">
              <LcdwUserView />
            </PermissionGuard>
          ),
        },
        {
          path: 'admin/lcdw-user/view-lcdw-users-activity',
          // element: <LcdwUserActivity />
          element: (
            <PermissionGuard moduleId="lcdw_users">
              <LcdwUserActivity />
            </PermissionGuard>
          ),
        },
        {
          path: 'admin/roles/list-of-roles',
          // element: <ListOfRoles />
          element: (
            <PermissionGuard moduleId="admin_users">
              <ListOfRoles />
            </PermissionGuard>
          ),
        },
        {
          path: 'admin/roles/add-roles',
          // element: <AddRoles />
          element: (
            <PermissionGuard moduleId="admin_users">
              <AddRoles />
            </PermissionGuard>
          ),
        },
        {
          path: 'admin/roles/editrole/:id',
          // element: <EditRoles />
          element: (
            <PermissionGuard moduleId="admin_users">
              <EditRoles />
            </PermissionGuard>
          ),
        },
        {
          path: 'admin/admin-users/user-roles/permission',
          // element: <Permission />
          element: (
            <PermissionGuard moduleId="admin_users">
              <Permission />
            </PermissionGuard>
          ),
        },
        {
          path: 'admin/admin-users/view-users',   // Admin User
          // element: <ListOfSubUser />
          element: (
            <PermissionGuard moduleId="admin_users">
              <ListOfSubUser />
            </PermissionGuard>
          ),
        },
        // {
        //   path: 'admin-users/sub-user-mangement/add-sub-user',
        //   element: <AddSubUser />
        // },
        // {
        //   path: 'admin-users/sub-user-mangement/edit-sub-user',
        //   element: <EditSubUser />
        // },
        {
          path: 'admin/admin-users/view-user-activity/:id',
          // element: <ViewSubUserActivity />
          element: (
            <PermissionGuard moduleId="admin_users">
              <ViewSubUserActivity />
            </PermissionGuard>
          ),
        },
        {
          path:'admin/company-subuser/:id',
          // element: <CompanySubuser/>

          element: (
            <PermissionGuard moduleId="company_users">
              <CompanySubuser />
            </PermissionGuard>
          ),
        },
        {
          path:'admin/admin-users/view-user-activity',
          // element: <ViewActivity />
          element: (
            <PermissionGuard moduleId="admin_users">
              <ViewActivity />
            </PermissionGuard>
          ),
        },
        {
          path: 'admin/staff-users/view-staff-users',     //Staff User
          // element: <ListOfAdminStaffUsers />
          element: (
            <PermissionGuard moduleId="staff_users">
              <ListOfAdminStaffUsers />
            </PermissionGuard>
          ),
        },
        // {
        //   path: 'admin-users/staff-user-mangement/add-staff-user',  
        //   element: <AddStaffUserActivity />
        // },
        {
          path: 'admin/staff-users/view-staff-users-activity',
          // element: <ViewStaffUserActivity />
          element: (
            <PermissionGuard moduleId="staff_users">
              <ViewStaffUserActivity />
            </PermissionGuard>
          ),
        },
        {
          path: 'admin/staff-users/view-staff-follow-uplist',
          // element: <ViewStaffFollowUpList />
          element: (
            <PermissionGuard moduleId="staff_users">
              <ViewStaffFollowUpList />
            </PermissionGuard>
          ),
        },
        {
          path: 'admin/feedback/contact',
          // element: <FeedbackContact />
          element: (
            <PermissionGuard moduleId="FeedbackList">
              <FeedbackContact />
            </PermissionGuard>
          ),
        },
        {
          path: 'admin/feedback/location',
          // element: <LocationFeedback />
          element: (
            <PermissionGuard moduleId="FeedbackList">
              <LocationFeedback />
            </PermissionGuard>
          ),
        },
        {
          path: 'admin/industry/list',
          // element: <IndustryList />
          element: (
            <PermissionGuard moduleId="Setting">
              <IndustryList />
            </PermissionGuard>
          ),
        },
        {
          path: 'admin/country/list',
          // element: <CountryList />
          element: (
            <PermissionGuard moduleId="Setting">
              <CountryList />
            </PermissionGuard>
          ),
        },
        {
          path: 'admin/location-logs',
          // element: < LocationLogs />
          element: (
            <PermissionGuard moduleId="Logs">
              <LocationLogs />
            </PermissionGuard>
          ),
        },
        {
          path: 'logs/login-logs',
          // element: <LoginLogs />
          element: (
            <PermissionGuard moduleId="Logs">
              <LoginLogs />
            </PermissionGuard>
          ),
        },
        {
          path: 'admin/check-list',
          element: <CartCheckList />
        },

        
      ]
    },
    {
      path: '/maintenance',
      element: <CommonLayout />,
      children: [
        {
          path: '404',
          element: <MaintenanceError />
        },
        {
          path: '500',
          element: <MaintenanceError500 />
        },
        {
          path: 'under-construction',
          element: <MaintenanceUnderConstruction />
        },
        {
          path: 'coming-soon',
          element: <MaintenanceComingSoon />
        }
      ]
    }
  ]
};

export default MainRoutes;
