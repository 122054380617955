// project-imports
import support from './support';
import location from './location';
import request from './request';
import payment from './payment';
import report from './report';
import cms from './cms';
import settings  from './setting';
import feedback from './feedback';
import logs from './logs';
// import mail from './email';

import company from './company';

import lcdw from './lcdw';

// ==============================|| MENU ITEMS ||============================== //


// Get loginType from sessionStorage
const loginType = sessionStorage.getItem('loginType');

// Define menu items array
let items = [];

// Optionally, you can filter menu items based on loginType
if (loginType === 'admin') {
  items = [support, location, request, payment, report, cms, settings, feedback, logs];
} else if (loginType === 'company') {
  // Define company specific menu items
  items = [company];
} else if (loginType === 'lcdw'){
  items = [lcdw];
}

const menuItems = {
  items: items
};

export default menuItems;