import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

// material-ui
// import { useTheme } from '@mui/material/styles';
// import logoDark from 'assets/images/2024-04-27-1714176035776-546897151 1.svg'
// import logopg from 'assets/images/Logo_r.svg'
/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */
// ==============================|| LOGO SVG ||============================== //
const LogoMain = () => {
  // const theme = useTheme();
  const [logoSrc, setLogoSrc] = useState('');

  const type ='landing';
  useEffect(() => {
    // Fetch logo when component mounts or theme mode changes
    const fetchLogo = async () => {
      try {
        // Make a request to your backend to fetch the logo
        let response; // Declare response variable in outer scope
        response = await axios.get(`https://showcaseyourprojects.com/service/api/get-siteadmin-logo/${type}`);
       

        if (response.data.success) {
          const baseURL = 'https://showcaseyourprojects.com/service/';
          const imageURL = baseURL + response.data.siteSetting.meta_value;
          setLogoSrc(imageURL);
        } else {
          console.error('Failed to fetch logo:', response.data.error);
        }
      } catch (error) {
        console.error('Error fetching logo:', error);
      }
    };

    fetchLogo();
  }, []); // Run effect when theme mode changes

  return (
    
      <img src={logoSrc} alt="Landing Logo" />
     
  );
};

LogoMain.propTypes = {
  reverse: PropTypes.bool
};

export default LogoMain;
