import { ReactComponent as box } from 'assets/images/sidemenu/box.svg';
import { ReactComponent as map } from 'assets/images/sidemenu/map.svg';

const icons = {
  box: box,
  map: map,
};


const storedUserDetails = localStorage.getItem('rolepermission');

let userpermission = null;
let loginpermission = [];

if (storedUserDetails) {
  try {
    userpermission = JSON.parse(storedUserDetails);
    loginpermission = userpermission.permissions || [];
  } catch (error) {
    console.error('Failed to parse storedUserDetails:', error);
  }
}

const location = {
  id: 'location',
  title: 'Location',
  type: 'group',
  children: [
    {
      id: 'Category',
      title: 'Category',
      type: 'collapse',
      icon: icons.box,
      children: [
        {
          id: 'view_cate',
          title: 'View Category',
          type: 'item',
          url: '/admin/category/view',
        },
        // {
        //   id: 'add-user',
        //   title: 'Add Category',
        //   type: 'item',
        //   url: '/admin/category/add',
        // }
      ]
    },
    {
      id: 'Location',
      title: 'Locations',
      type: 'collapse',
      icon: icons.map,
      children: [
        {
          id: 'locat_list',
          title: 'Location List',
          type: 'item',
          url: '/admin/location/list',
        },
        {
          id: 'add-user',
          title: 'Photo Approvals',
          type: 'item',
          url: '/admin/location/photo-approval',
        },
        {
          id: 'location-orders',
          title: 'Location Orders',
          type: 'item',
          url: '/admin/location/location-orders ',
        }
        // {
        //   id: 'add-user',
        //   title: 'Add Locations',
        //   type: 'item',
        //   url: '/admin/location/add',
        // }
      ]
    }

  ]
};


const modulesToCheck = ['Category', 'Location'];

if (location && Array.isArray(location.children) && loginpermission && Array.isArray(loginpermission)) {

  
  modulesToCheck.forEach(moduleId => {
    const module = location.children.find(child => child.id === moduleId);

    if (module) {
      const modulePermission = loginpermission.find(permission => permission.module === moduleId);

      console.log(`${moduleId} Permission:`, modulePermission);

      if (modulePermission) {
        if (modulePermission.create === 0 &&
          modulePermission.read === 0 &&
          modulePermission.update === 0 &&
          modulePermission.deletePermission === 0) {
          location.children = location.children.filter(child => child.id !== moduleId);
        }
      }
    }
  });
} else {
  console.error('support.children or loginpermission is undefined or not an array');
}

if (location.children.length === 0) {
  location.title = '';
}
export default location;