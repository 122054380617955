import { useEffect, useState } from 'react';
import {  useNavigate } from 'react-router-dom';

// project-imports
import Routes from 'routes';
 import ThemeCustomization from 'themes';
//import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Loader from 'components/Loader';

import LandingPage from 'components/LandingPage';

import ContactUs from 'pages/dashboard/mainContact';

import Locales from 'components/Locales';
//import RTLLayout from 'components/RTLLayout';
import ScrollTop from 'components/ScrollTop';
import Snackbar from 'components/@extended/Snackbar';
import Notistack from 'components/third-party/Notistack';

import { dispatch } from 'store';
import { fetchMenu } from 'store/reducers/menu';

// auth-provider
  import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
 import { JWTProvider as CompanyProvider } from 'contexts/CompanyJWTProvider';
 import { JWTProvider as LcdwProvider } from 'contexts/LcdwJWTProvider';
//  import Loadable from 'components/Loadable';
// import { lazy } from 'react';
// import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';
// import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext';
// import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //
// const PagesLanding = Loadable(lazy(() => import('./components/LandingPage')));

const App = () => {
  const [loading, setLoading] = useState(true);
  const [loginType, setLoginType] = useState('landingPage');
  const navigate = useNavigate();

  useEffect(() => {

      /* multiple login logic start*/

      var logintype = localStorage.getItem('logintype');
      const url = window.location.href;

      if(logintype =='admin')
      {
        setLoginType('admin');
    
         if (url.includes('/company')) {
            navigate('/admin');
        } else if (url.includes('/lcdw')) {
            navigate('/admin');
        }else{
           // alert("Please check Url!"); //future
        }

      }else if(logintype =='company')
      {

        setLoginType('company');

        if (url.includes('/admin')) {
            navigate('/company');
        } else if (url.includes('/lcdw')) {
            navigate('/company');
        }else{
          // alert("Please check Url!");//future
        } 

      }else if(logintype =='lcdw')
      {

        setLoginType('lcdw');

        if (url.includes('/admin')) {
            navigate('/lcdw');
        } else if (url.includes('/company')) {
            navigate('/lcdw');
        }else{
        // alert("Please check Url!");//future
        } 
        
      }else
      {

        const url = window.location.href;
        if (url.includes('/admin')) {
          setLoginType('admin');
        } else if (url.includes('/company')) {
          setLoginType('company');
        } else if (url.includes('/lcdw')) {
          setLoginType('lcdw');
        } else if (url.includes('/Contact-us')) {
          setLoginType('contact');
        } else {
          setLoginType('landingPage');
        }
      
      }

   

    //   alert(url);

   
    /* multiple login logic end */




    dispatch(fetchMenu()).then(() => {
      setLoading(false);
    });
  }, []);

  const renderAuthProvider = () => {
    switch (loginType) {

      // ADMIN ROUTES
      case 'admin':
        return  <ThemeCustomization>
        {/* <RTLLayout> */}
          <Locales>
            <ScrollTop>
              <AuthProvider>
                <>
                  <Notistack>
                    <Routes  loginType={loginType} />  
                    <Snackbar />
                  </Notistack>
                </>
              </AuthProvider>
            </ScrollTop>
          </Locales>
        {/* </RTLLayout> */}
      </ThemeCustomization>;

      // COMPANY ROUTES
      case 'company':
        return <ThemeCustomization>
        {/* <RTLLayout> */}
          <Locales>
            <ScrollTop>
              <CompanyProvider>
                <>
                  <Notistack>
                    <Routes loginType={loginType} />  
                    <Snackbar />
                  </Notistack>
                </>
              </CompanyProvider>
            </ScrollTop>
          </Locales>
        {/* </RTLLayout> */}
      </ThemeCustomization>;

      // LCDW ROUTES
      case 'lcdw':
        return <ThemeCustomization>
        {/* <RTLLayout> */}
          <Locales>
            <ScrollTop>
              <LcdwProvider>
                <>
                  <Notistack>
                    <Routes loginType={loginType} />  
                    <Snackbar />
                  </Notistack>
                </>
              </LcdwProvider>
            </ScrollTop>
          </Locales>
        {/* </RTLLayout> */}
      </ThemeCustomization>;

       // CONTACT-US ROUTE
       case 'contact':
        return <ContactUs />;
      
      default:
        return <LandingPage />;
    }
  };

  if (loading) return <Loader />;

  return (

    renderAuthProvider()
  );
};

export default App;
