import PropTypes from 'prop-types';
import { useEffect } from 'react';
import {  useNavigate } from 'react-router-dom';

// project-imports
import useAdminAuth from 'hooks/useCompanyAuth';

// ==============================|| AUTH GUARD ||============================== //

const CompanyAuthGuard = ({ children }) => {
  const { isLoggedIn } = useAdminAuth();
  const navigate = useNavigate();
  //const location = useLocation();

  useEffect(() => {
    if (!isLoggedIn) {
      // Reload the page when not logged in
    
      // Navigate to the default path after reloading
      navigate('/company'); // Replace '/default-path' with your desired default path
      window.location.reload();
    }
  }, [isLoggedIn, navigate]);



  return children;
};

CompanyAuthGuard.propTypes = {
  children: PropTypes.node
};

export default CompanyAuthGuard;
