
//import  CustomIcon from 'assets/images/logo-icon.svg'
import { ReactComponent as dashboard } from 'assets/images/sidemenu/dashboard.svg';
import { ReactComponent as user } from 'assets/images/sidemenu/user.svg';

const icons = {
  dashboard: dashboard,
  user: user,
};

const storedUserDetails = localStorage.getItem('rolepermission');

let userpermission = null;
let loginpermission = [];

if (storedUserDetails) {
  try {
    userpermission = JSON.parse(storedUserDetails);
    loginpermission = userpermission.permissions || [];
  } catch (error) {
    console.error('Failed to parse storedUserDetails:', error);
  }
}

const support = {
  id: 'navigation',
  title: 'Navigation',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: 'admin/dashboard',
      icon: icons.dashboard
    },
    {
      id: 'company_users',
      title: 'Company Users',
      type: 'collapse',
      icon: icons.user,
      breadcrumbs: true,
      children: [
        {
          id: 'view-company',
          title: 'View Company',
          type: 'item',
          url: '/admin/company/view',

        },
        {
          id: 'view-user',
          title: 'View Users',
          type: 'item',
          url: '/admin/company-users/view',

        }
      ]
    },
    {
      id: 'lcdw_users',
      title: 'LCDW Users',
      type: 'collapse',
      icon: icons.user,
      children: [
        {
          id: 'view-users',
          title: 'View Users',
          type: 'item',
          url: '/admin/lcdw-user/view',

        },

        {
          id: 'add-user',
          title: 'View User Activity',
          type: 'item',
          url: '/admin/lcdw-user/view-lcdw-users-activity',

        },
        {
          id: 'request',
          title: 'Requests',
          type: 'item',
          url: '#',

        }
      ]
    },
    {
      id: 'staff_users',
      title: 'Staff Users',
      type: 'collapse',
      icon: icons.user,
      children: [
        {
          id: 'view-staff-users',
          title: 'View Users',
          type: 'item',
          url: '/admin/staff-users/view-staff-users',
        },
        {
          id: 'view-staff-users-activity',
          title: 'View User Activity',
          type: 'item',
          url: '/admin/staff-users/view-staff-users-activity',
        },
        {
          id: 'view-staff-follow-uplist',
          title: 'Follow Up List',
          type: 'item',
          url: '/admin/staff-users/view-staff-follow-uplist',
        }
      ]
    },
    {
      id: 'admin_users',
      title: 'Admin Users',
      type: 'collapse',
      breadcrumbs: true,
      icon: icons.user,
      children: [
        {
          id: 'view-users',
          title: 'View User',
          type: 'item',
          url: '/admin/admin-users/view-users',
        },
        {
          id: 'view-user-activity',
          title: 'View User Activity',
          type: 'item',
          url: '/admin/admin-users/view-user-activity',
        },

        {
          id: 'roles',
          title: 'Roles',
          type: 'collapse',
          breadcrumbs: true,
          icon: icons.seti,
          children: [
            {
              id: 'list-of-roles',
              title: 'List of Roles',
              type: 'item',
              url: '/admin/roles/list-of-roles'
            },
            // {
            //   id: 'add-role',
            //   title: 'Add Roles',
            //   type: 'item',
            //   url: '/admin/roles/add-roles'
            // },
          ]
        },
        {
          id: 'permission',
          title: 'Permission',
          type: 'item',
          url: '/admin/admin-users/user-roles/permission',
        },


      ]
    },
    // {
    //   id: 'industry',
    //   title: 'Industry',
    //   type: 'collapse',
    //   icon: icons.user,
    //   breadcrumbs: true,
    //   children: [
    //     {
    //       id: 'industry-list',
    //       title: 'Industry List',
    //       type: 'item',
    //       url: '/admin/industry/list',
    //     }
    //   ]
    // },
    // {
    //   id: 'country',
    //   title: 'Country',
    //   type: 'collapse',
    //   icon: icons.user,
    //   breadcrumbs: true,
    //   children: [
    //     {
    //       id: 'country-list',
    //       title: 'Country List',
    //       type: 'item',
    //       url: '/admin/country/list',
    //     }
    //   ]
    // },
  ]

};


const modulesToCheck = ['company_users', 'lcdw_users', 'staff_users', 'admin_users'];

// Ensure support.children is an array before proceeding
if (support && Array.isArray(support.children) && loginpermission && Array.isArray(loginpermission)) {
  modulesToCheck.forEach(moduleId => {
    // Check if the child module exists in the support.children array
    const module = support.children.find(child => child.id === moduleId);

    if (module) {
      // Find the corresponding module permission
      const modulePermission = loginpermission.find(permission => permission.module === moduleId);

      console.log(`${moduleId} Permission:`, modulePermission);

      // Check if the modulePermission exists and evaluate its permissions
      if (modulePermission) {
        if (modulePermission.create === 0 &&
          modulePermission.read === 0 &&
          modulePermission.update === 0 &&
          modulePermission.deletePermission === 0) {
          // Remove the module from support.children if no permissions are granted
          support.children = support.children.filter(child => child.id !== moduleId);
        }
      }
    }
  });
} else {
  console.error('support.children or loginpermission is undefined or not an array');
}


export default support;
