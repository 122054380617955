import { Link as RouterLink } from 'react-router-dom';
import { useEffect, useState  } from 'react';

import { Link, Stack, Typography } from '@mui/material';

// ==============================|| MAIN LAYOUT - FOOTER ||============================== //

const Footer = () => {

  const currentYear = new Date().getFullYear();
  const [appName, setAppName] = useState('');

  useEffect(() => {
    const fetchAppName = async () => {
      try {
        // Fetch app name from backend API
        const response = await axios.get('https://showcaseyourprojects.com/service/api/get-siteappname-logo');
        if (response.data.success) {
          const appName = response.data.siteSetting.meta_value;
          setAppName(appName);
        } else {
          console.error('Failed to fetch app name:', response.data.error);
        }
      } catch (error) {
        console.error('Error fetching app name:', error);
      }
    };
    fetchAppName();
  }, []);
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ p: '24px 16px 0px', mt: 'auto' }}>
      <Typography variant="caption">
        &copy; {currentYear} {appName} . All Rights Reserved.
      </Typography>
      <Stack spacing={1.5} direction="row" justifyContent="space-between" alignItems="center">
        <Link
          component={RouterLink}
          to="#"
          target="_blank"
          variant="caption"
          color="textPrimary"
          sx={{
            "&:hover": {
              color: "blue"
            }
          }}
        >
          Support
        </Link>
        <Link
          component={RouterLink}
          to="#"
          target="_blank"
          variant="caption"
          color="textPrimary"
          sx={{
            "&:hover": {
              color: "blue"
            }
          }}
        >
          Terms of Use
        </Link>
      </Stack>
    </Stack>
  );
  
};

export default Footer;
