// import PropTypes from 'prop-types';
import React, {  useState } from 'react';
import Swal from 'sweetalert2';
// material-ui
import { Button, Checkbox, FormControlLabel, Grid, InputLabel, Stack, Typography, TextField } from '@mui/material';

// third-party
import MainCard from 'components/MainCard';
// import '../../assets/css/contact.css';
import axios from 'axios';

// project-imports
import AnimateButton from 'components/@extended/AnimateButton';


// ==============================|| CONTACT US - FORM ||============================== //

function ContactForm() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email_id: "",
    phone_no: "",
    message: "",
    is_agree: false, 
  });

  const [errors, setErrors] = useState({});
    const [submitting, setSubmitting] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: ''
        }));
    };

    const handleCheckboxChange = (e) => {
        setFormData((prevData) => ({
            ...prevData,
            is_agree: e.target.checked ? 1 : 0 
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitting(true);
    
        try {
            const postData = {
                firstName: formData.firstName,
                lastName: formData.lastName,
                email_id: formData.email_id,
                phone_no: formData.phone_no,
                message: formData.message,
                is_agree: formData.is_agree
            };
    
            const res = await axios.post('https://showcaseyourprojects.com/service/api/contact', postData);
    
            await Swal.fire({
                icon: 'success',
                title: 'Success',
                text: res.data.message,
                customClass: {
                    confirmButton: 'swal-confirm-button-class',
                }
            });
    
            // Clear form data
            setFormData({
                firstName: '',
                lastName: '',
                email_id: '',
                phone_no: '',
                message: '',
                is_agree: false,
            });
    
            // Clear errors
            setErrors({});
    
        } catch (err) {
            console.error('Error adding contact:', err);
            if (err.response && err.response.data && err.response.data.error) {
                setErrors(err.response.data.error);
            } else {
                setErrors({ general: 'Error, something went wrong. Please try again.' });
            }
        } finally {
            setSubmitting(false);
        }
    };
    
    return (
      // <>
      <MainCard class="css-1deu1v4-MuiPaper-root-MuiCard-root" >
          <Grid padding={5}>
              <Typography variant="h5" gutterBottom sx={{ mb: 2 }}>
                  Contact Us
              </Typography>
              <form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>

                      <Grid item xs={12} sm={6}>
                          <Stack spacing={1}>
                              <InputLabel>First Name</InputLabel>
                              <TextField
                                  id="firstName"
                                  name="firstName"
                                  placeholder="First Name *"
                                  value={formData.firstName}
                                  onChange={handleChange}
                                  error={!!errors.firstName}
                                  helperText={errors.firstName}
                              />
                          </Stack>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                          <Stack spacing={1}>
                              <InputLabel>Last Name</InputLabel>
                              <TextField
                                  id="lastName"
                                  name="lastName"
                                  placeholder="Last Name *"
                                  value={formData.lastName}
                                  onChange={handleChange}
                                  error={!!errors.lastName}
                                  helperText={errors.lastName}
                              />
                          </Stack>
                      </Grid>
                      <Grid item xs={12}>
                          <Stack spacing={1}>
                              <InputLabel>Email id</InputLabel>
                              <TextField
                                  id="email_id"
                                  name="email_id"
                                  placeholder="E-mail id *"
                                  fullWidth
                                  autoComplete="email_id"
                                  value={formData.email_id}
                                  onChange={handleChange}
                                  error={!!errors.email_id}
                                  helperText={errors.email_id}
                              />
                          </Stack>
                      </Grid>
                      <Grid item xs={12}>
                          <Stack spacing={1}>
                              <InputLabel>Phone Number</InputLabel>
                              <TextField
                                  id="phone_no"
                                  name="phone_no"
                                  placeholder="Phone number *"
                                  fullWidth
                                  autoComplete="phone_no"
                                  value={formData.phone_no}
                                  onChange={handleChange}
                                  error={!!errors.phone_no}
                                  helperText={errors.phone_no}
                              />
                          </Stack>
                      </Grid>
                    
                      <Grid item xs={12}>
                          <Stack spacing={1}>
                              <InputLabel>Message</InputLabel>
                              <TextField
                                  id="message"
                                  name="message"
                                  placeholder="Message *"
                                  fullWidth
                                  autoComplete="message"
                                  multiline
                                  rows={6}
                                  value={formData.message}
                                  onChange={handleChange}
                                  error={!!errors.message}
                                  helperText={errors.message}
                              />
                          </Stack>
                      </Grid>
                      
                      <Grid item xs={12}>
                          <FormControlLabel
                              control={
                              <Checkbox
                                      color="primary"
                                      name="is_agree"
                                      checked={formData.is_agree === 1}
                                      onChange={handleCheckboxChange}
                                  />
                          }
                              label="I agree to all the Terms & Condition"
                          />
                      </Grid>
                      <Grid item xs={12}>
                          <Stack direction="row" justifyContent="flex-end">
                              <AnimateButton>
                              <Button
                                      fullWidth
                                      variant="contained"
                                      sx={{ my: 3, ml: 1 }}
                                      type="submit"
                                      disabled={submitting}
                                  >
                                      Submit
                                  </Button>
                              </AnimateButton>
                          </Stack>
                      </Grid>
                  </Grid>
              </form>
          </Grid>
      </MainCard>
      //  </>
  );
}

export default ContactForm;
