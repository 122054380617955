import React, { useMemo } from 'react';
import { Navigate } from 'react-router-dom';

const PermissionGuard = ({ children, moduleId }) => {
  console.log('Checking moduleId:', moduleId);

  // Retrieve stored user permissions from localStorage
  const storedUserDetails = localStorage.getItem('rolepermission');
  const userPermissions = storedUserDetails ? JSON.parse(storedUserDetails).permissions : [];

  // Memoize the module permission check
  const modulePermission = useMemo(() => {
    return userPermissions.find(permission => permission.module === moduleId);
  }, [userPermissions, moduleId]);

  console.log('modulePermission:', modulePermission);

  // Check if the modulePermission exists and if the user has any rights (create, read, update, delete)
  if (!modulePermission || (
    modulePermission.create === 0 && 
    modulePermission.read === 0 && 
    modulePermission.update === 0 && 
    modulePermission.deletePermission === 0
  )) {
    // Redirect to a 404 page or any other fallback
    return <Navigate to="/" />;
  }

  // Render the children components if permission is granted
  return children;
};

export default PermissionGuard;
