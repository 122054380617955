import { ReactComponent as reporti } from 'assets/images/sidemenu/reports.svg';

const icons = {
  reporti: reporti
};

const storedUserDetails = localStorage.getItem('rolepermission');

let userpermission = null;
let loginpermission = [];

if (storedUserDetails) {
  try {
    userpermission = JSON.parse(storedUserDetails);
    loginpermission = userpermission.permissions || [];
  } catch (error) {
    console.error('Failed to parse storedUserDetails:', error);
  }
}

const reports = {
    id: '',
    title: 'reports',
    type: 'group',
    children: [

        {
            id: 'Reports',
            title: 'Reports',
            type: 'collapse',
            icon:icons.reporti,
            children: [
             {
                    id: '',
                    title: 'Location Reports',
                    type: 'item',
                    url: '/test',
             },
              {
                id: '',
                title: 'Payment Reports',
                type: 'item',
                url: '/test',
              },
              {
                id: '',
                title: 'Withdrawal Reports',
                type: 'item',
                url: '/test',
             
              }
             
            ]
          },
  
   
    ]
  };


  const modulesToCheck = ['Reports'];

if (reports && Array.isArray(reports.children) && loginpermission && Array.isArray(loginpermission)) {

  modulesToCheck.forEach(moduleId => {
    const module = reports.children.find(child => child.id === moduleId);

    if (module) {
      const modulePermission = loginpermission.find(permission => permission.module === moduleId);

      console.log(`${moduleId} Permission:`, modulePermission);

      if (modulePermission) {
        if (modulePermission.create === 0 &&
            modulePermission.read === 0 &&
            modulePermission.update === 0 &&
            modulePermission.deletePermission === 0) {
            reports.children = reports.children.filter(child => child.id !== moduleId);
        }
      }
    }
  });
} else {
  console.error('support.children or loginpermission is undefined or not an array');
}

  if (reports.children.length === 0) {
    reports.title = '';
  }

  export default reports;